<template>
  <svg
    id="Component_443_1"
    data-name="Component 443 – 1"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 28.886 25.293"
  >
    <path
      id="Path_3905"
      :style="iconStyle"
      data-name="Path 3905"
      d="M383,260.117l.4,5.334H370.5a37.26,37.26,0,0,1,.036-3.918,1.607,1.607,0,0,1,1.76-1.413C375.849,260.112,379.406,260.117,383,260.117Z"
      transform="translate(-370.46 -254.708)"
      fill="#267c26"
    />
    <path
      id="Path_3906"
      data-name="Path 3906"
      d="M393.8,298.912a2.781,2.781,0,0,1-2.971.192,18.552,18.552,0,0,1-5.3-4.308,2.015,2.015,0,0,0-1.342-.689c-4.321-.052-8.644-.032-12.966-.027a6.689,6.689,0,0,0-.748.083c0,2.819,0,5.564,0,8.308,0,1.9.55,2.442,2.5,2.442,7.968,0,15.935-.015,23.9.019a2.634,2.634,0,0,0,2.481-1.147v-9.709A19.582,19.582,0,0,1,393.8,298.912Zm-15.188.527a8.571,8.571,0,0,1-1.9.04v.009a5.915,5.915,0,0,1-1.671-.04c-.369-.113-.626-.592-.932-.909.3-.281.59-.778.914-.8a21.734,21.734,0,0,1,3.583.009,1.181,1.181,0,0,1,.805.842A1.227,1.227,0,0,1,378.612,299.438Z"
      transform="translate(-370.471 -279.638)"
      fill="#267c26"
    />
    <path
      id="Path_3907"
      data-name="Path 3907"
      d="M432.467,239.813a1.437,1.437,0,0,0-.962.141c-1.9.788-3.792,1.581-5.66,2.433-.357.163-.85.6-.842.9a45.148,45.148,0,0,0,.43,7.264c.666,3.478,3.352,5.456,6.247,7.1a1.242,1.242,0,0,0,1.037-.034c2.385-1.341,4.541-2.958,5.7-5.541a26.588,26.588,0,0,0,.971-3.067v-6.1C437.086,241.877,434.788,240.818,432.467,239.813Zm2.85,7.12c-1.054,1.314-2.084,2.646-3.152,3.949-.67.817-1.18.8-1.781-.042-.542-.76-1.042-1.55-1.557-2.33-.337-.509-.389-1.077.225-1.3a1.446,1.446,0,0,1,1.2.344,8.65,8.65,0,0,1,1.087,1.551c.943-1.183,1.779-2.239,2.624-3.288.37-.459.795-.884,1.41-.417C436.036,245.9,435.724,246.426,435.317,246.933Z"
      transform="translate(-410.505 -239.771)"
      fill="#267c26"
    />
  </svg>
</template>

<script>
// TODO: This icon is not used anywhere
export default {
  props: {
    height: {
      type: String,
      default: '16.199',
    },
    width: {
      type: String,
      default: '16.199',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
